<template>
  <div class="row mt-1 pt-1">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="col-md-12">
            <b-alert v-if="isError" 
              :show="dismissCountDown"
              dismissible
              variant="danger"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
             `Something is Error` {{ messageError }} 
            </b-alert>
            <b-alert v-if="isShow" 
              :show="dismissCountDown"
              dismissible
              variant="success"
              @dismissed="dismissCountDown=0"
              @dismiss-count-down="countDownChanged"
            >
              {{ message }} 
            </b-alert>
          </div>
          <div class="col-md-12">
            <div class="form">
              <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(actionCreate)">
                <div class="form-group row">
                  <label for="" class="col-md-3"> Name Campaign <br /> </label>
                  <div class="col-sm-7">
                    <ValidationProvider name="name" rules="required|min:5" v-slot="{ errors }">
                      <input v-model="Form.name" type="text" class="form-control">
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>  
                </div>  
                <div class="form-group row">
                  <span class="col-sm-3">Select Range</span>
                  <div class="col-md-8">
                    <v-date-picker
                      v-model="range"
                      mode="date"
                      :masks="masks"
                      is-range
                      :min-date="new Date()"
                    >
                      <template v-slot="{ inputValue, inputEvents, isDragging }">
                        <div class="rows">
                          <input
                            class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                            :value="inputValue.start"
                            v-on="inputEvents.start"
                            :min-date="new Date()"
                          />

                          <span class="p-2">to</span>

                          <input
                            class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                            :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                            :value="inputValue.end"
                            v-on="inputEvents.end"
                            :min-date="new Date()"
                          />
                        </div>
                      </template>
                    </v-date-picker>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="" class="col-md-3"> Description  <br /> </label>
                  <div class="col-sm-7">
                     <ValidationProvider name="description" rules="required|min:10" v-slot="{ errors }">
                      <textarea  v-model="Form.description" id="" cols="30" rows="10" class="form-control">
                      </textarea>
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>  
                </div>
                <div class="form-group row">
                  <label for="" class="col-md-3">  Total Cost Campaign <br /> </label>
                  <div class="col-sm-3">
                    <input type="number" min="0" v-model="Form.totalCost" id="" class="form-control">
                  </div>  
                </div>    
                <div class="form-group row">
                  <label for="" class="col-md-3">  Active</label>
                  <div class="col-md-3">
                    <b-form-checkbox v-model="Form.isActive" name="check-button" switch>
                      {{ Form.isActive ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="form-group row justify-content-center">
                  <div class="col-md-5">
                    <b-button
                        v-if="!isLoading"
                        lg="4"
                        type="submit"
                        variant="primary"
                        class="px-4 w-100"
                      >
                        Save
                      </b-button>
                  </div>
                </div>  
              </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment  from 'moment'
import { mapActions } from "vuex";
import API from '../../plugins/http'
  export default {
    name:'CreateCampaign',
    data() {
      return {
        Form:{
          name:'',
          startDate:'',
          endDate:'',
          description:'',
          totalCost:0,
          isActive: false,
        },
        isLoading: false,
        isError: false,
        isShow: false,
        isShowError: false,
        message: '',
        messageError: '',
        dismissSecs: 5,
        dismissCountDown: 0,
        Dates: {
          from: moment().format("YYYY,MM,DD"),
          to: moment().format("YYYY,MM,DD"),
        },
        range: {
          start: moment().format("YYYY,MM,DD"),
          end: moment().format("YYYY,MM,DD"),
        },
        masks: {
          input: "YYYY-MM-DD",
        },
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler(to) {
          document.title = to.meta.title || 'Create Campaign | Content Management System Ebooks Gramedia.com';
        }
      },
    },
    methods: {
      ...mapActions("campaign", [ "create"]),
      async actionCreate() {
        console.log("event submit", this.Form)
        this.messageError = null
        this.isError = false;
        this.isLoading = true;
        let payload = {
          name: this.Form.name,
          startDate: moment(this.range.start).format("YYYY-MM-DD"),
          endDate: moment(this.range.end).format("YYYY-MM-DD"),
          description: this.Form.description,
          totalCost: this.Form.totalCost,
          isActive : this.Form.isActive,
        }
        await API.post('campaign', payload)
          .then((response) => {
            this.isLoading = false
            this.message = response.data.message
            this.messageError = null
            this.showAlert()
            this.$router.push({ path: '/campaign' })
          })
          .catch((error) => {
            console.log(error)
            const err = error.response?.data || error.message
            this.isLoading = false
            this.messageError = err.errors
            this.showAlertError()
          })
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
        this.isShow = true
      },
      showAlertError() {
        this.dismissCountDown = this.dismissSecs
        this.isError = true
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
    },
  }
</script>

<style scoped>

</style>